import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap"; 
import { AiOutlineCheckCircle } from 'react-icons/ai'

function BetonarmeAraclar() {

  return (
    <section>
      
      <Container fluid className="home-sectison" id="homes">
        <Container className="home-content">
          <Row>
            <Col md={7}>
              <h1 style={{ paddingBottom: 15 }} >
                Betonarme Araçlar{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻 Revit Autocad  Araçları
                </span>
              </h1> 
              <iframe width="560" height="315" title="Etex" src="https://www.youtube.com/embed/tk0W0NoQAX4?si=6HY03VigL3lbR79X" frameBorder="0" allowFullScreen></iframe>

            </Col> 

            <Col md={5} style={{ paddingBottom: 20 }}>
              <p>
                <span className="purple">Betonarme Araçlar</span>, Revit yazılımında bir <span className="purple">Add-In</span> olarak, AutoCAD yazılımında ise .dll dosyası şeklinde yüklenerek kullanılabilen kapsamlı bir araç grubudur. Bu araçlar, statik betonarme projelendirme süreçlerini <span className="purple">otomatikleştirerek</span> mühendislerin ve mimarların iş yükünü önemli ölçüde azaltır.
                <br /><br /><span className="purple">Betonarme Araçlar</span>, Revit'te 3D donatı çizimi yaparken, <span className="purple">kalıp metrajını otomatik</span> olarak hesaplar ve düzenler. Ayrıca, projede yer alan tüm elemanlar için <span className="purple">otomatik isimlendirme</span>, <span className="purple">tiplendirme</span> ve <span className="purple">ölçülendirme</span> işlemlerini gerçekleştirir. Bu sayede, proje sürecinde <span className="purple">zaman kazandırır</span> ve olası hataları minimize eder.
                <br /><br />AutoCAD'de ise, .dll dosyası olarak entegre edilen araçlar, <span className="purple">uygulama çizimlerini</span> en yüksek doğrulukla oluşturur. <span className="purple">Betonarme elemanların</span> doğru bir şekilde <span className="purple">isimlendirilmesi</span>, <span className="purple">tiplerinin belirlenmesi</span> ve <span className="purple">ölçülerinin hassasiyetle yapılması</span>, projelerin eksiksiz ve yönetmeliklere uygun bir şekilde tamamlanmasını sağlar.
                <br /><br /><span className="purple">Betonarme Araçlar</span>, betonarme projelendirme süreçlerini hızlandırırken, aynı zamanda projelerin kalitesini artırarak, daha <span className="purple">güvenilir sonuçlar</span> elde etmenize yardımcı olur. Bu araçlar, hem Revit hem de AutoCAD kullanıcıları için güçlü bir entegrasyon sunarak, projelerin her aşamasında <span className="purple">verimliliği maksimize eder</span>.
              </p>

              <br />
            <Button href="/costumer">Betonarme Araçları İndir</Button>
            </Col> 
          </Row>  
        </Container>

      </Container> 
    </section>
  );
}


function EtexTool(props) {

  return <div className="mt-4" style={{ backgroundColor:'#3D3C40', borderRadius: 5, padding: 6, transition: 'transform 0.3s ease-in-out'  }}>
    <h2 className="purple" href={props.id}>{props.id} - {props.title}</h2>
    <section className="tool_content" id={props.id}>
      <Row className="flex-row m-5" >
        <Col md={4} lg={6} xs={12}>
          <iframe width="100%" height="100%" src={props.video_uri} title={props.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </Col>
        <Col md={4} lg={6} xs={12} >
          {props.properties.map(item => {
            return <li style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'baseline' }}><AiOutlineCheckCircle size={20} className="purple" /><p >{item}</p></li>
          })}        </Col>
      </Row>
    </section>
  </div>
}
export default BetonarmeAraclar;
