import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
    <Card.Body>
      <blockquote className="blockquote mb-0">
        <p style={{ textAlign: "justify" }}>
          Biz, <span className="purple">İstanbul</span> merkezli bir mühendislik ve yazılım geliştirme firmasıyız. 
          Yapı tasarımı, <span className="purple">statik hesaplamalar</span> ve <span className="purple">yazılım geliştirme</span> alanlarında uzmanlaşarak projeleriniz için yenilikçi çözümler sunuyoruz. 
          Ekip olarak, inşaat mühendisliği ve yazılım geliştirme alanlarında derin bir bilgi birikimine sahibiz ve gelişmiş teknolojilerle projelerinizi en verimli şekilde hayata geçiriyoruz.
        </p>
        
        <h3 className="purple">Neler Yapıyoruz?</h3>
        <p style={{ textAlign: "justify" }}>
          <span className="purple">Yapı Tasarımı</span> | <span className="purple">Statik Hesaplar</span>: Projelerimizde, <span className="purple">doğrusal</span> ve <span className="purple">doğrusal olmayan hesaplamalar</span>, 
          performans hesapları ve şekildeğiştirmeye göre tasarım gibi ileri mühendislik tekniklerini kullanıyoruz. 
          Bu hesaplamalarla yapıların güvenliğini ve dayanıklılığını optimize ederek, en karmaşık projelerde bile ideal çözümler sunuyoruz.
        </p>
        
        <p style={{ textAlign: "justify" }}>
          <span className="purple">BIM Projelendirme</span>: BIM (Building Information Modeling) ile projelerimizin dijital modellerini hazırlıyoruz 
          ve süreçleri daha hızlı, entegre ve verimli hale getiriyoruz. Özellikle <span className="purple">Revit</span> ve <span className="purple">Etabs</span> gibi ileri teknolojiler kullanarak, 
          hem tasarım hem de modelleme süreçlerini yönetiyoruz. Bu yazılımların API'lerini kullanarak özelleştirilmiş çözümler geliştiriyoruz.
        </p>
        
        <p style={{ textAlign: "justify" }}>
          <span className="purple">Yazılım Geliştirme</span>: Yazılım geliştirme tarafında, özellikle inşaat sektöründe iş süreçlerini dijitalleştirmek ve hızlandırmak isteyen firmalara özel yazılımlar yazıyoruz. 
          <span className="purple"> Revit</span>, <span className="purple">Etabs</span> gibi yazılımların API'lerini kullanarak, projelere entegre yazılım çözümleri sunuyoruz. 
          Müşterilerimizin ihtiyaçlarına göre özel yazılım çözümleri geliştiriyor, iş akışlarını optimize ediyor ve projelerin sorunsuz ilerlemesini sağlıyoruz.
        </p>
        
        <h3 className="purple">Kimlere Hizmet Veriyoruz?</h3>
        <p style={{ textAlign: "justify" }}>
          Yapı projelerinin her aşamasında, performans odaklı ve güvenilir tasarımlar ile mühendislik hesaplarına ihtiyaç duyan inşaat firmalarına hizmet veriyoruz. 
          Ayrıca, yazılım geliştirmek isteyen firmalara, API entegrasyonu yaparak projeye özel yazılım çözümleri sunuyoruz.
        </p> 
      </blockquote>
    </Card.Body>
  </Card>
  
  );
}

export default AboutCard;
