import React, { useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";

import bim from "../../Assets/hizmetlerimiz/bim.png";
import performans from "../../Assets/hizmetlerimiz/perfromans.png";
import statikproje from "../../Assets/hizmetlerimiz/statikproje.png";
import yazilim from "../../Assets/hizmetlerimiz/yazilim.png";
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaGlobe } from "react-icons/fa";

function ResumeNew() {

  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredMail, setIsHoveredMail] = useState(false);

  const servicesData = [
    {
      title: "Yazılım Geliştirme (BIM - Revit API)",
      description:
        "Yazılım geliştirme alanında, inşaat sektöründe iş süreçlerini dijitalleştirmek ve hızlandırmak isteyen firmalara özel yazılımlar yazıyoruz. Revit API'sini kullanarak projelere entegre yazılım çözümleri sunuyoruz.",
      imageUrl: yazilim, // Yazılım Geliştirme resmi URL'si
    },
    {
      title: "Statik Projelendirme",
      description:
        "Yapıların güvenliğini ve dayanıklılığını sağlamak için statik projelendirme yapıyoruz. Doğrusal ve doğrusal olmayan hesaplamalar ile en karmaşık projelerde bile ideal çözümler sunuyoruz.",
      imageUrl: statikproje, // Statik Proje resmi URL'si
    },
    {
      title: "Performans Analizi (Şekildeğiştirmeye Göre Tasarım)",
      description:
        "Projelerimizde performans analizi yaparak, yapıların şekil değiştirmeye göre tasarımını optimize ediyoruz. Bu sayede yapıların dayanıklılığını artırıyoruz.",
      imageUrl: performans, // Performans Analizi resmi URL'si
    },
    {
      title: "BIM Projelendirme",
      description:
        "BIM (Building Information Modeling) ile projelerimizin dijital modellerini hazırlıyor ve süreçleri daha hızlı, entegre ve verimli hale getiriyoruz. Revit ve Etabs gibi ileri teknolojiler kullanarak projelerinizi en iyi şekilde yönetiyoruz.",
      imageUrl: bim, // BIM Projelendirme resmi URL'si
    },
  ];

  return (
    <Container fluid className="services-section" style={{ padding: "20px", marginTop: "80px" }}>
      <Container>
        <h1 className="project-heading">
          Hizmetlerimiz<strong className="purple">.</strong>
        </h1>
        <Row>
          {servicesData.map((service, index) => (
            <Col md={6} xs={12} key={index} style={{
              marginBottom: "20px", border: "1px solid", padding: "4px", borderRadius: "10px", boxShadow: "0px 6px 20px rgba(200, 220, 250, 0.5)",
            }}>
              <Card style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <Col xs={12} style={{ padding: 0 }}>
                  <Card.Img
                    variant="top"
                    src={service.imageUrl}
                    alt={service.title}
                    style={{ height: "200px", objectFit: "cover" }} // Resmi kapsaması için stil
                  />
                </Col>
                <Col xs={12}>
                  <Card.Body>
                    <Card.Title style={{ fontSize: "1.5em", color: "purple" }}>
                      {service.title}
                    </Card.Title>
                    <Card.Text style={{ textAlign: "justify" }}>
                      {service.description}
                    </Card.Text>
                  </Card.Body>
                </Col>
              </Card>
            </Col>
          ))}
        </Row>


        {/* Contact Form Section */}
        <Container fluid className="contact-section" style={{ padding: "20px", marginTop: "40px" }}>
          <h1 className="project-heading">Bize Ulaşın<strong className="purple"></strong></h1>
          <Row style={{ justifyContent: "center" }}>
            <Col md={6}>
              <Card style={{ padding: "20px", border: "none" }}>
                <Card.Body>
                  <Card.Title style={{ fontSize: "1.5em", color: "purple" }}>İletişim Bilgileri</Card.Title>
                  <Row style={{ marginBottom: "10px", cursor: isHovered ? 'pointer' : 'default', }} onClick={a => window.open("https://wa.me/+905539878725", "_blank")} onMouseEnter={() => setIsHovered(true)} // Fare üzerine geldiğinde durumu değiştir
                    onMouseLeave={() => setIsHovered(false)} >
                    <Col md={1}>
                      <FaPhone size={24} />
                    </Col>
                    <Col md={11}>
                      <Card.Text style={{ marginLeft: "10px", color: "blue" }}  >
                        Telefon: +90 553 987 8725
                      </Card.Text>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "10px" }}>
                    <Col md={1}>
                      <FaEnvelope size={24} />
                    </Col>
                    <Col md={11}>
                      <Card.Text style={{ marginLeft: "10px", color:"blue",cursor: isHoveredMail ? 'pointer' : 'default', }} onClick={a => window.open("mailto:rufai.demir@yaani.com", "_blank")} onMouseEnter={() => setIsHoveredMail(true)} // Fare üzerine geldiğinde durumu değiştir
                    onMouseLeave={() => setIsHoveredMail(false)} >
                        E-posta: rufai.demir@yaani.com
                      </Card.Text>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "10px" }}>
                    <Col md={1}>
                      <FaMapMarkerAlt size={24} />
                    </Col>
                    <Col md={11}>
                      <Card.Text style={{ marginLeft: "10px" }}>
                        Adres: İstanbul, Türkiye
                      </Card.Text>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "10px" }}>
                    <Col md={1}>
                      <FaGlobe size={24} />
                    </Col>
                    <Col md={11}>
                      <Card.Text style={{ marginLeft: "10px" }}>
                        www.rufaidemir.com.tr
                      </Card.Text>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </Container>
  );
}

export default ResumeNew;
