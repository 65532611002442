import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Home2 from "./Home2";
import betonarmearaclar from "../../Assets/betonarmearaclar.gif"
import etexlogo from "../../Assets/ETEX_LOGO.png"
import netexlogo from "../../Assets/NETEX_LOGO.png"
import betonarmearaclarlogo from "../../Assets/StatikArackar.png"
import { FaWhatsapp, FaEnvelope } from 'react-icons/fa';

function Home() {
  const [currentTitleIndex, setCurrentTitleIndex] = useState(0);
  const navigate = useNavigate();

  const titles = [
    "Projelerinizi Revit'e entegre ederek BIM teknolojisinin avantajlarından yararlanın ve sektörde önde olun.",
    "Statik projelendirme artık BIM-Revit ortamında daha kolay ve daha hızlı.",
    "Statik projelendirmede detayları Revit modeli üzerinden 'Betonarme Araçlar' ile alın.",
    "Doğrusal hesaplar için Etabs modellerinden verileri alıp işlemek için 'Etex' ile tanışın.",
    "Nonlinear hesapları Etabs ile yapmak ve analiz sonuçlarını almak 'Netex' ile çok kolay.",
  ];
  // useEffect ile belirli aralıklarla başlığı değiştir
  useEffect(() => {
    const interval = setInterval(() => {
      // Başlıklar dizisindeki sonraki başlığa geç
      setCurrentTitleIndex((prevIndex) => (prevIndex + 1) % titles.length);
    }, 12000); // 5 saniyede bir değiştir

    // Temizlik (cleanup) fonksiyonu
    return () => clearInterval(interval);
  }, [titles.length]); // titles.length ile useEffect'in sadece başlık sayısı değiştiğinde çalışmasını sağlıyoruz

  return (
    <section>
      <Container className="container-fluid"  >
        <Col>
          <Row>
            <Col>
              <Row>
                <Col
                  style={{
                    borderRadius: ".5rem",
                    backgroundImage: `linear-gradient(to right, rgba(0,1,51,0.95), rgba(0,0,0,0.38), rgba(0, 0, 0, 0.5), rgba(200, 200, 255,0.40)), url(${betonarmearaclar})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '50vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    textShadow: '3px 3px 10px rgba(0,0,0,0.9)',
                    marginBottom: '40px',
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                >
                  {/* Ekstra arka plan kutusu */}
                  <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1,
                    borderRadius: '.5rem'
                  }} />

                  {/* Dinamik başlık */}
                  <h1
                    style={{
                      zIndex: 2,
                      background: `rgba(34,41,51,0.05)`,
                      margin: "5px",
                      padding: "60px 30px",
                      letterSpacing: "2px",
                      lineHeight: "1.5",
                      width: "100%",
                      borderRadius: "10px",
                      boxShadow: "0px 6px 20px rgba(200, 10, 250, 0.5)",
                      maxWidth: "100%",
                      textAlign: "center",
                      color: '#f0f0f0',
                      animation: 'slideText 10s infinite',
                    }}
                  >
                    {titles[currentTitleIndex]}
                  </h1>

                  <style jsx>{`
       @keyframes slideText {
        0% {
          transform: translateX(-100%); /* Soldan gelir */
        }
        40% {
          transform: translateX(0); /* Ortaya gelir */
        }
        60% {
          transform: translateX(0); /* Ortada bekler (3 saniye) */
        }
        100% {
          transform: translate(100vw, 100vh) scale(0); /* Sağ alt köşeye küçülerek gider */
        }
      }

      .text {
        animation: slideText 6s ease-in-out forwards; /* Toplam süre: 6 saniye */
        position: absolute;
        left: 0; /* Sol kenar */
        bottom: 0; /* Alt kenar */
        width: 200px; /* Genişlik */
        height: 100px; /* Yükseklik */
        background-color: lightblue; /* Arka plan rengi */
        display: flex;
        justify-content: center;
        align-items: center;
      }

        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        .fadeInList li {
          opacity: 0;
          animation: fadeIn 8s forwards;
        }

        .fadeInList li:nth-child(1) {
          animation-delay: 8s;
        }

        .fadeInList li:nth-child(2) {
          animation-delay: 16s;
        }

        .fadeInList li:nth-child(3) {
          animation-delay: 24s;
        }

        .fadeInList li:nth-child(4) {
          animation-delay: 32s;
        }
          .fadeInList li:nth-child(5) {
          animation-delay: 40s;
        }
      `}</style>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Row>
              <h1
                style={{
                  zIndex: 2,
                  background: `rgba(34,41,51,0.55)`,
                  margin: "5px",
                  padding: "15px",
                  letterSpacing: "2px",
                  lineHeight: "1.5",
                  width: "100%",
                  borderRadius: "10px",
                  boxShadow: "5px 6px 20px rgba(250, 250, 250, 0.5)",
                  maxWidth: "100%",
                  textAlign: "center",
                  marginBottom: "50px",
                  color: '#f0f0f0',
                }}
              >
                YAZILIMLAR
              </h1>
            </Row>
            <Col sm={4} className="d-flex">
              <Card className="w-100 project-card-view" style={{ backgroundColor: 'transparent', }} onClick={(e) => document.getElementById('etex').scrollIntoView({ behavior: 'smooth', block: 'center' })}>
                <Card.Img
                  variant="top"
                  src={etexlogo}
                  style={{
                    objectFit: 'contain',
                    backgroundColor: 'transparent',
                    height: '250px', // Resmin yüksekliğini ayarladık
                    marginBottom: '10px',
                    borderBottom: "1px solid  rgba(255, 220, 250, 0.5)",
                    boxShadow: "0 4px 10px  rgba(255, 220, 250, 0.25)",

                  }}
                />
                <Card.Body style={{ padding: '20px' }}> {/* Daha fazla boşluk için padding artırıldı */}
                  <Card.Title style={{ color: "#ffffff" }}>Etex</Card.Title>
                  <Card.Text style={{ color: "#ffffff" }}>CSI Etabs ile entegre çalışan bu yazılım, mühendislik projelerinizdeki verimliliği artırırken kaliteyi de en üst seviyeye çıkarmaktadır. Doğrusal analizlerdeki güvenilirlik ve doğruluk ile projelerinizi bir adım öne taşıyın!
                    Deprem analizi, spektrum hesabı, kapasite kontrolleri, kesme kontrolleri, eğilme moment kontrolleri ve donatı seçimi gibi birçok araç ile projelendirme süresinde büyük bir kazanım sağlar.</Card.Text>
                  <Button variant="primary" target="_blank" href="https://drive.google.com/drive/folders/1zS0nOHAiP_JGnJ6iZkvgh3lunNto80QT?usp=drive_link">Örnek Hesap Raporları ve Dosyaları </Button>
                </Card.Body>
              </Card>
            </Col>

            <Col sm={4} className="d-flex">
              <Card className="w-100 project-card-view" style={{ backgroundColor: 'transparent' }} onClick={(e) => document.getElementById('betonarmearaclar').scrollIntoView({ behavior: 'smooth', block: 'center' })}>
                <Card.Img
                  variant="top"
                  src={betonarmearaclarlogo}
                  style={{
                    objectFit: 'contain',
                    backgroundColor: 'transparent',
                    height: '250px', // Resmin yüksekliğini ayarladık
                    marginBottom: '10px',
                    borderBottom: "1px solid  rgba(255, 220, 250, 0.5)",
                    boxShadow: "0 4px 10px  rgba(255, 220, 250, 0.25)",
                  }}
                />
                <Card.Body style={{ padding: '20px' }}> {/* Daha fazla boşluk için padding artırıldı */}
                  <Card.Title style={{ color: "#ffffff" }}>Betonarme Araçlar</Card.Title>
                  <Card.Text style={{ color: "#ffffff" }}>Revit, Autocad ve Etabs API larını kullanarak Revit verilerini Autocad ortamında elemanların açılımlarını uygulama detayında çizer.
                    Revit - Etabs model aktarımını modeli optimize ederek katı model üzerinden tek yönlü olarak gerçekleştirir.
                    Elemanların tiplendirilmesi, isimlendirilmesi, ölçülendirilmesi ve daha bir çok işi Revit ortamında gerçekleştirir.
                    Donatıları 3D olarak modeller.</Card.Text>
                  <Button variant="primary" target="_blank" href="https://drive.google.com/drive/folders/1I_cbAw0JV0xuSPO44exUKWFZ2ufbLFUN?usp=sharing">Örnek Çizimler ve Metrajlar </Button>
                </Card.Body>
              </Card>
            </Col>

            <Col sm={4} className="d-flex">
              <Card className="w-100 project-card-view" style={{ backgroundColor: 'transparent' }} onClick={(e) => document.getElementById('netex').scrollIntoView({ behavior: 'smooth', block: 'center' })}>
                <Card.Img
                  variant="top"
                  src={netexlogo}
                  style={{
                    objectFit: 'contain',
                    backgroundColor: 'transparent',
                    height: '250px', // Resmin yüksekliğini ayarladık
                    marginBottom: '10px',
                    borderBottom: "1px solid  rgba(255, 220, 250, 0.5)",
                    boxShadow: "0 4px 10px  rgba(255, 220, 250, 0.25)",
                  }}
                />
                <Card.Body style={{ padding: '20px' }}> {/* Daha fazla boşluk için padding artırıldı */}
                  <Card.Title style={{ color: "#ffffff" }}>Netex</Card.Title>
                  <Card.Text style={{ color: "#ffffff" }}>CSI Etabs ile entegre çalışan bu yazılım, mühendislik projelerinizdeki verimliliği artırırken kaliteyi de en üst seviyeye çıkarmaktadır. Şekil değiştirmeye dayalı (Nonlinear) analizlerde güvenilirlik ve doğruluk ile projelerinizi bir adım öne taşıyın!
                    Sınır değerleri hesaplama, mafsal tanımlama ve atama, kontrol ve raporlama gibi birçok işlemi saniyeler içinde, en ince detaylara müdahale edebilecek şekilde sizin yerinize gerçekleştirir.</Card.Text>
                  <Button variant="primary" target="_blank" href="https://drive.google.com/drive/folders/1lm8rJEVzCrRYWca-EHzXENcIPhmT87eE?usp=sharing">Örnek Hesap Raporları ve Dosyaları </Button>
                </Card.Body>
              </Card>
            </Col>

          </Row>

        </Col>

        {/* Product Detail Section */}
        <Container fluid className="mt-5">
          <Row className="align-items-center mb-5" id="etex">
            <Col sm={6}>
              <video
                src="https://res.cloudinary.com/dw4fofzk8/video/upload/v1728386794/i8bu7z5qhta3zbi2dmzc.mp4"
                style={{ background: "transparent", borderRadius: "8px", zIndex: 1 }}
                loop
                muted // Ses kapalı
                autoPlay
                className="img-fluid w-100"
              />
            </Col>
            <Col sm={6}>
              <img src={etexlogo} alt="Etex Logo" style={{ width: "100px" }} />
              <p style={{ color: "#ffff", font: "bold", fontSize: "1rem" }}>
                <ul style={{ color: "#ffff", padding: 0, listStyle: "none", textAlign: "start", zIndex: 10, position: "relative" }}>
                  <li style={{ marginBottom: "10px" }}>
                    Etabs yazılımını ile entegre şekilde projelendirme için gerekli araçları içerir.
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    Etex betonarme hesaplar için CSI Etabs yazılımı ve MS Excel ile entegre çalışan, hesap modellerinde tanımlanması gereken parametreleri otomatik bir şekilde tanımlayabilir.
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    Hesap modelinde yapılması gereken kontrolleri Etabs API ve Excel API kullanarak hızlıca yapmaya olanak sağlayan ve bu çıktıları hesap raporu oluşturabilecek şekilde Word, PDF ve Excel formatında kullanıcılara çıktı üreten bir ara yazılımdır.
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    Formatlanmış Excel dosyaları kullanıcıların çeşitli parametreleri dinamik bir şekilde değiştirebilmelerine olanak tanır.
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    Excel dosyaları istenilen formatta düzenlenebilir.
                  </li>
                </ul>
              </p>
              <Button
                onClick={() => navigate("/etex")}
                variant="primary"
                style={{ zIndex: 10, position: "relative" }}
              >
                Detaylı İncele
              </Button>
            </Col>
          </Row>

          <Row className="align-items-center mb-5" id="betonarmearaclar">
            <Col sm={6} className="order-sm-2">
              <video
                src="https://res.cloudinary.com/dw4fofzk8/video/upload/v1728399560/d71hnp064ipnqyrvqu1v.mp4"
                style={{ background: "transparent", borderRadius: "8px" }}
                loop
                muted // Ses kapalı
                autoPlay
                className="img-fluid w-100"
              />
            </Col>
            <Col sm={6} >
              <img src={betonarmearaclarlogo} alt="Betonarme Araclar Logo" style={{ width: "140px" }} />
              <p style={{ color: "#ffff", font: "bold", fontSize: "1rem" }}>
                <ul style={{ color: "#ffff", padding: 0, listStyle: "none", textAlign: "start", zIndex: 10, position: "relative" }}>
                  <li style={{ marginBottom: "10px" }}>
                    Revit ve Autocad API'lerini kullanarak uygulama detay çizimlerini Autocad veya Revit ortamında oluşturur.
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    Revit modelini ETABS yazılımına aktarabilir.
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    Taşıyıcı sistem elemanlarını tiplendirerek pafta sayısından tasarruf sağlar.
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    Autocad'de, metraj yazılımıyla entegre çalışır.
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    Kalıp ve donatı metrajı gibi hesaplamaları saniyeler içinde Revit modelinde gerçekleştirir.
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    Etex yazılımı ile seçilen donatıları Revit modelindeki elemanlara aktarır.
                  </li>
                </ul>
              </p>
              <Button
                onClick={() => navigate("/betonarmearaclar")}
                variant="secondary"
                style={{ zIndex: 10, position: "relative" }}
              >
                Detaylı İncele
              </Button>
            </Col>
          </Row>

          <Row className="align-items-center mb-5" id="netex">
            <Col sm={6}>
              <video
                src="https://res.cloudinary.com/dw4fofzk8/video/upload/v1728471553/on15cktykdlquvcpnh4v.mp4"
                style={{ background: "transparent", borderRadius: "8px", zIndex: 1 }}
                loop
                muted // Ses kapalı
                autoPlay
                className="img-fluid w-100"
              />
            </Col>
            <Col sm={6}>
              <img src={netexlogo} alt="Netex Logo" style={{ width: "100px" }} />
              <p style={{ color: "#ffff", font: "bold", fontSize: "1rem" }}>
                <ul style={{ color: "#ffff", padding: 0, listStyle: "none", textAlign: "start", zIndex: 10, position: "relative" }}>
                  <li style={{ marginBottom: "10px" }}>
                    Etabs yazılımını ile entegre şekilde şekil değiştirmeye göre tasarım için gerekli araçları içerir.
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    Netex, doğrusal olmayan (nonlinear) tasarımlar için CSI Etabs ve MS Excel ile entegre çalışan bir yazılımdır. Hesap modellerinde dönme ve şekildeğiştirme sınırlarını hesaplar, elemanlar üzerinde mafsal (hinge) oluşturarak bu mafsalları elemanlara atar. Sonuçları CSI Etabs'ten alarak detaylı raporlar oluşturur.
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    Hesap modelinde yapılması gereken kontrolleri Etabs API ve Excel API kullanarak hızlıca yapmaya olanak sağlayan ve bu çıktıları hesap raporu oluşturabilecek şekilde Word, PDF ve Excel formatında kullanıcılara çıktı üreten bir ara yazılımdır.
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    Formatlanmış Excel dosyaları kullanıcıların çeşitli parametreleri dinamik bir şekilde değiştirebilmelerine olanak tanır.
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    Excel dosyaları istenilen formatta düzenlenebilir.
                  </li>
                </ul>
              </p>
              <Button
                onClick={() => navigate("/netex")}
                variant="primary"
                style={{ zIndex: 10, position: "relative" }}
              >
                Detaylı İncele
              </Button>
            </Col>
          </Row>
        </Container>

        <div className="contact-buttons">
  {/* Email Butonu */}
  <a
    href="mailto:rufai.demir@yaani.com"
    target="_blank"
    rel="noopener noreferrer"
    className="email-button"
  >
    <FaEnvelope />
    <span className="button-text">Bize Ulaşın</span>
  </a>

  {/* WhatsApp Butonu */}
  <a
    href="https://wa.me/+905539878725"
    target="_blank"
    rel="noopener noreferrer"
    className="whatsapp-button"
  >
    <FaWhatsapp />
    <span className="button-text">Bize Ulaşın</span>
  </a>

  <style jsx>{`
    .contact-buttons {
      position: fixed;
      right: 20px;
      bottom: 20px;
      display: flex;
      flex-direction: row;
      gap: 10px; /* Spacing between buttons */
      z-index: 1000;
    }

    .whatsapp-button,
    .email-button {
      background-color: #25D366;
      color: white;
      border-radius: 50px;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
      text-decoration: none;
      font-size: 28px;
      transition: all 0.3s ease-in-out;
      overflow: hidden;
      position: relative;
    }

    .button-text {
      display: none;
      margin-left: 10px;
      font-size: 16px;
      font-weight: bold;
      white-space: nowrap;
    }

    /* Hover effect for both buttons */
    .whatsapp-button:hover,
    .email-button:hover {
      width: 200px;
      color: white;
    }

    .whatsapp-button:hover .button-text,
    .email-button:hover .button-text {
      display: block;
      color: white;
    }

    /* Specific styles for Email Button */
    .email-button {
      background-color: #007bff; /* Email button color */
    }

    /* Move buttons when either is hovered */
    .whatsapp-button:hover + .email-button {
      transform: translateX(-140px); /* Move email button to the left when WhatsApp is hovered */
    } 
  `}</style>
</div>



 
      </Container>

      <Home2 />
    </section>
  );
}

export default Home;
